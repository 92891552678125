.theme-dark {
  --color-portfolio-asset: #8b93a7;
  --color-portfolio-assetBg: #04363f;
}

.theme-light {
  --color-portfolio-asset: #717888;
  --color-portfolio-assetBg: #eff3f8;
}

.portfolio_asset_background {
  background: var(--color-portfolio-assetBg);
}

.portfolio_asset_text {
  color: var(--color-portfolio-asset);
}
