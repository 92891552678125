.theme-dark {
  --color-sidebar-mainBg: #001321;
  --shadow-sidebar: none;
}

.theme-light {
  --color-sidebar-mainBg: #ffffff;
  --shadow-sidebar: 10px 0px 40px rgba(0, 0, 0, 0.1);
}

.sidebar_container {
  background: var(--color-sidebar-mainBg);
  box-shadow: var(--shadow-sidebar);
}
