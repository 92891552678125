.theme-dark {
  --color-table-mainBg: #001321;
  --color-table-border: #5b5b5b;
  --color-table-rowOddBg: rgba(8, 34, 53, 0.6);
}

.theme-light {
  --color-table-mainBg: #ffffff;
  --color-table-border: transparent;
  --color-table-rowOddBg: #eff3f8;
}

.table_custom {
  border-collapse: unset;
  background: var(--color-table-mainBg);
  border: 1px solid var(--color-table-border);

  @apply border-spacing-0;
}

.table_custom_header_cell {
  @apply p-3 first:pl-8 text-left text-secondary-1 font-normal;
}

.table_custom_cell {
  @apply p-3 first:pl-8 text-left text-sm;
}

.table_custom_row:nth-child(2n + 1) > td {
  background-color: var(--color-table-rowOddBg);
}
