:root {
  --width-scrollThumb: 6px;
}

.theme-dark {
  --color-primary-1: #fff;
  --color-secondary-1: #a3a3a3;
  --color-success-1: #00e3b4;
  --color-success-2: #00e3b4;
  --color-danger-1: #ff5151;
  --color-danger-2: #ff6363;
  --color-info-1: #a3a3a3;
  --color-warning-1: #fff495;
  --color-dark: #000000;
  --color-highlight: #00e3b4;

  --color-primaryBg-1: #001321;
  --color-primaryBg-2: #010c14;
  --color-primaryBg-3: rgba(8, 34, 53, 0.6);
  --color-successBg-1: #00e3b4;

  --color-navbar-mainBg: rgba(8, 34, 53, 0.6);
  --color-navbar-border: rgba(255, 255, 255, 0.5);

  --color-border-1: #5b5b5b;
  --color-border-2: #001321;

  --body-background: radial-gradient(
      50% 100% at 0% 0%,
      rgba(0, 227, 180, 0.15) 0%,
      rgba(8, 34, 53, 0.6) 100%
    ),
    radial-gradient(
      50% 100% at 100% 100%,
      rgba(197, 228, 243, 0.16) 0%,
      rgba(197, 228, 243, 0) 100%
    ),
    var(--color-primaryBg-1);

  --shadow-primary: none;

  --color-chart-line: #00dfb1;
  --color-chart-axis: #a3a3a3;

  --color-tooltip-mainBg: #082235;
  --color-tooltip-border: #5b5b5b;

  --color-scrollThumb: #5c5c5c;

  --color-select-field-mainBg: #082235;
  --color-select-field-inputText: #ffffff;
  --color-select-field-placeholderText: #a3a3a3;
  --color-select-field-menu-border: #081621;
  --color-select-field-multiValueBg: #010c14;
}

.theme-light {
  --color-primary-1: #202020;
  --color-secondary-1: #787878;
  --color-success-1: #87eed9;
  --color-success-2: #00a372;
  --color-danger-1: #ff5151;
  --color-danger-2: #ff6363;
  --color-info-1: #202020;
  --color-warning-1: #ff9e2e;
  --color-dark: #202020;
  --color-highlight: #202020;

  --color-primaryBg-1: #eff3f8;
  --color-primaryBg-2: #e9effc;
  --color-primaryBg-3: #ffffff;
  --color-successBg-1: #87eed9;

  --color-navbar-mainBg: #fafbfc;
  --color-navbar-border: #cdcdcd;

  --color-border-1: transparent;
  --color-border-2: transparent;

  --body-background: #eff3f8;

  --shadow-primary: 0px 3px 8px rgba(0, 0, 0, 0.1);

  --color-chart-line: #87eed9;
  --color-chart-axis: #787878;

  --color-tooltip-mainBg: #eff3f8;
  --color-tooltip-border: #cdcdcd;

  --color-scrollThumb: #d8d8d8;

  --color-select-field-mainBg: #eff3f8;
  --color-select-field-inputText: #202020;
  --color-select-field-placeholderText: #a3a3a3;
  --color-select-field-menu-border: #e6e6e6;
  --color-select-field-multiValueBg: #dbdee3;
}
