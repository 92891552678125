.theme-dark {
  --color-dropdown-mainBg: #010c14;
}

.theme-light {
  --color-dropdown-mainBg: #ffffff;
}

.dropdown_background {
  background: var(--color-dropdown-mainBg);
}
