@import './variables.css';
@import './animations.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: url('../assets/fonts/montserrat.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html {
  font-family: 'Montserrat';
}

body {
  background: var(--body-background);
  color: var(--color-primary-1);
  min-height: 100vh;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  outline: none;
}
