.theme-dark {
  --color-footer-border: #cdcdcd;
}

.theme-light {
  --color-footer-border: #cdcdcd;
}

.footer_border {
  color: var(--color-footer-border);
}
