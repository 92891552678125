.theme-dark {
  --color-modal-mainBg: #001321;
  --color-modal-overlayBg: rgba(0, 0, 0, 0.65);
}

.theme-light {
  --color-modal-mainBg: #ffffff;
  --color-modal-overlayBg: rgba(0, 0, 0, 0.65);
}

.modal_background {
  background: var(--color-modal-mainBg);
}

.modal_overlay_background {
  background: var(--color-modal-overlayBg);
  backdrop-filter: blur(2px);
}
