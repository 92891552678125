.theme-dark {
  --color-pagination-main: #ffffff;
  --color-pagination-mainBg: #082235;
  --color-pagination-disabled: rgba(96, 96, 96, 0.561);
  --color-pagination-disabledBg: #001321;
  --color-pagination-border: rgba(96, 96, 96, 0.561);
}

.theme-light {
  --color-pagination-main: #202020;
  --color-pagination-mainBg: #ffffff;
  --color-pagination-disabled: #787878;
  --color-pagination-disabledBg: #eff3f8;
  --color-pagination-border: transparent;
}

.pagination_button {
  background: var(--color-pagination-mainBg);
  color: var(--color-pagination-main);
  border: 1px solid var(--color-pagination-border);

  @apply w-8 h-8 rounded-md;
  @apply flex items-center justify-center mx-2 shadow-primary;
}

.pagination_button:disabled {
  background: var(--color-pagination-disabledBg);
  color: var(--color-pagination-disabled);
}

.pagination_input {
  background: var(--color-pagination-mainBg);
  border: 1px solid var(--color-pagination-border);

  @apply w-12 h-8 text-center rounded-md appearance-none;
  @apply mx-2 px-1 shadow-primary;
}
