.theme-dark {
  --color-search-field-mainBg: #010c14;
  --color-search-field-mainBorder: #000000;
  --color-search-field-inputText: #ffffff;
  --color-search-field-placeholderText: #a3a3a3;
}

.theme-light {
  --color-search-field-mainBg: #eff3f8;
  --color-search-field-mainBorder: #e6e6e6;
  --color-search-field-inputText: #202020;
  --color-search-field-placeholderText: #a3a3a3;
}
