.theme-dark {
  --color-notification-mainBg: #001321;
}

.theme-light {
  --color-notification-mainBg: #ffffff;
}

.notification_background {
  background: var(--color-notification-mainBg);
}
