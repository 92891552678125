.theme-dark {
  --color-landing-description-border: rgba(255, 255, 255, 0.2);
}

.theme-light {
  --color-landing-description-border: rgba(169, 169, 169, 0.2);
}

.landing_coin {
  width: 13%;
  position: absolute;
  -webkit-animation: coin-animation 4s 0.5s infinite
    cubic-bezier(0.37, 0, 0.63, 1);
  animation: coin-animation 4s 0.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
}

.landing_page_desktop_particles {
  background: url(../../assets/images/landing/particles.svg);
  mix-blend-mode: color-dodge;
}

.landing_page_desktop_background {
  background: rgba(0, 227, 180, 0.4);
  filter: blur(80px);
}

.landing_description_text {
  border-color: var(--color-landing-description-border);
  @apply border-b md:border-b-0 md:border-r p-5;
  @apply last:border-0;
}

@keyframes coin-animation {
  50% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
