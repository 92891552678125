.theme-dark {
  --color-card-mainBg: #001321;
  --color-card-border: #5b5b5b;
}

.theme-light {
  --color-card-mainBg: #ffffff;
  --color-card-border: transparent;
}

.card_container {
  background: var(--color-card-mainBg);
  border: 1px solid var(--color-card-border);
}
