.theme-dark {
  --color-theme-switchBg: #052030;
  --color-theme-toggleBg: #00e3b4;
}

.theme-light {
  --color-theme-switchBg: #e5e5e5;
  --color-theme-toggleBg: #ffffff;
}

.theme_switch_background {
  background: var(--color-theme-switchBg);
}

.theme_toggle_background {
  background: var(--color-theme-toggleBg);
}
