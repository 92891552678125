.theme-dark {
  --color-input-mainBg: #082235;
}

.theme-light {
  --color-input-mainBg: #eff3f8;
}

.input_background {
  background: var(--color-input-mainBg);
}
