.theme-dark {
  --color-table-unlock-headerBg: #001321;
  --color-table-unlock-bodyBg: rgba(8, 34, 53, 0.6);
  --color-table-unlock-border: #5b5b5b;
}

.theme-light {
  --color-table-unlock-headerBg: #ffffff;
  --color-table-unlock-bodyBg: #eff3f8;
  --color-table-unlock-border: #cdcdcd;
}

.table_unlock_header {
  background: var(--color-table-unlock-headerBg);
  border-top: 0.5px solid var(--color-table-unlock-border);
  border-bottom: 0.5px solid var(--color-table-unlock-border);
}

.table_unlock_body {
  background: var(--color-table-unlock-bodyBg);
  border-bottom: 0.5px solid var(--color-table-unlock-border);
}

.table_unlock_header th {
  @apply p-3 first:pl-8 text-left text-secondary-1 font-normal;
}

.table_unlock_body td {
  @apply p-2 first:pl-8 text-left text-sm;
}
