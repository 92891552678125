.theme-dark {
  --color-btn-primary: rgba(22, 23, 28, 1);
  --color-btn-primaryBg: #00e3b4;
  --color-btn-secondary: #00e3b4;
  --color-btn-secondaryBorder: #00e3b4;
  --color-btn-info: #a3a3a3;
  --color-btn-infoBg: rgba(196, 196, 196, 0.3);
}

.theme-light {
  --color-btn-primary: rgba(22, 23, 28, 1);
  --color-btn-primaryBg: #87eed9;
  --color-btn-secondary: #202020;
  --color-btn-secondaryBorder: #989898;
  --color-btn-info: #a3a3a3;
  --color-btn-infoBg: rgba(196, 196, 196, 0.3);
}

.btn_primary {
  color: var(--color-btn-primary);
  background: var(--color-btn-primaryBg);
}

.btn_secondary {
  color: var(--color-btn-secondary);
  border: 1px solid var(--color-btn-secondaryBorder);
}

.btn_info {
  color: var(--color-btn-info);
  background: var(--color-btn-infoBg);
}
